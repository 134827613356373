.navbar {
  background-color: #060b26;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: rgb(249, 250, 251);
  width: 200px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 150ms;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: rgb(249, 250, 251);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.menu-button,
.menu-button-open {
  display: none;
}

@media (max-width: 970px) {
  .menu-button,
  .menu-button-open {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .menu-button-burger {
    width: 17px;
    height: 2px;
    background: #000;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.3s ease-in-out;
  }

  .menu-button-burger::before,
  .menu-button-burger::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 17px;
    height: 2px;
    background: #000;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.3s ease-in-out;
  }

  .menu-button-burger::before {
    transform: translateY(-6px);
  }
  .menu-button-burger::after {
    transform: translateY(6px);
  }

  .menu-button-open .menu-button-burger {
    transform: translateX(25px);
    background: transparent;
    box-shadow: none;
  }

  .menu-button-open .menu-button-burger::before {
    transform: rotate(45deg) translate(-17.5px, 17.5px);
  }
  .menu-button-open .menu-button-burger::after {
    transform: rotate(-45deg) translate(-17.5px, -17.5px);
  }
}
