@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: smaller;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  display: flex;
  margin-right: 6rem;
}

.burger-menu-icon {
  display: none;
}

.service-text-wrapper {
  width: 100%;
  margin-left: 2rem;
  margin-top: 0.5rem;
}

.service-wrapper {
  display: flex;
  justify-content: center;
  margin-left: 25%;
  margin-right: 10%;
}

.navbar-logo-wrapper {
}

.service-title-wrapper {
  margin-left: 20%;
}

.navbar-wrapper {
  padding: 1.25rem;
}

.ImageSlider-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
}

.logo-inh-wrapper {
  font-weight: 400;
  font-size: 1rem /* 16px */;
  line-height: 1.5rem /* 24px */;
}

.contact-wrapper {
  display: flex;
  justify-content: space-between;
}

.contact-call-wrapper {
  width: 100%;
}

.contact-form-wrapper {
  width: 33.333%;
  margin-right: 12rem;
}

.dataprotection-paragraph-spacing {
  margin-left: 18rem;
  margin-top: 6rem;
  margin-bottom: 6rem;
  width: 50%;
}

#root {
  height: 100vh;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.dataprotection-paragraph h1 {
  font-weight: 700;
  font-size: 1.25rem;
  color: gray;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.btn-tertiary-light:hover {
  color: rgb(200, 200, 200);
}

.btn-tertiary:hover {
  color: rgb(100, 100, 100);
}

.btn-primary {
  background-color: rgb(100, 116, 139);
  color: white;
}

.btn-primary:hover {
  background-color: rgb(130, 146, 169);
  color: white;
}

.dataprotection-paragraph p {
  margin-bottom: 1rem;
  color: rgb(100, 100, 100);
  font-size: 1rem;
}
.dataprotection-paragraph a {
  margin-bottom: 1rem;
  color: blue;
  text-decoration: underline;
  font-size: 1rem;
}

.shadow-around-soft {
  box-shadow: 0px 0px 5px rgb(0, 0, 0, 0.25);
}

.text-color-primary {
  color: rgb(0, 0, 255);
}

.slide-in {
  animation-duration: 50ms;
  animation-name: slidein;
}
@keyframes slidein {
  from {
    height: 0rem;
  }
  to {
    height: 12rem;
  }
}

.input-Send-Message {
  width: 65%;
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 15px;
  background-color: rgb(244, 244, 244);
}

textarea {
  resize: none;
}

.button-form-send-message {
  width: 65%;
}

.defaulthidden {
  transform: scale(0.7);
  transition: all 0.5s ease-in-out;
}

.defaultshow {
  transform: scale(0.9);
  z-index: -1;
}

@media (max-width: 970px) {
  .wrapper {
    display: none;
  }

  .dataprotection-paragraph-spacing {
    margin: auto;
    width: 80%;
  }

  .contact-wrapper {
    display: block;
  }

  .contact-form-wrapper {
    width: 100%;

    margin-top: 6rem;
  }

  .burger-menu-icon {
    display: block;
  }

  .service-wrapper {
    display: block;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 3.5rem;
  }

  .service-text-wrapper {
    width: 85%;
    margin: 2rem auto;
  }
  .navbar-logo-wrapper {
    width: 60%;
  }
  .navbar-logo-wrapper h1 {
    font-size: 1rem;
  }

  .service-title-wrapper {
    margin-left: 3%;
  }

  .logo-inh-wrapper {
    font-weight: 400;
    margin-left: 0.5rem;
    font-size: 0.9rem /* 16px */;
    line-height: 1.5rem /* 24px */;
  }

  .ImageSlider-wrapper {
    display: none;
  }
  .navbar-wrapper {
    padding: 0.5rem 1.25rem 0.5rem;
  }
}

.defaulthidden {
  transform: scale(0.7);
  transition: all 0.5s ease-in-out;
}

.defaultshow {
  transform: scale(1);
}
